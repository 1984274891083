export const MULTIPLE_VOUCHER = 'If you purchased multiple vouchers, enter your first code on this page and confirm your booking details. When you return to this page to enter your next activation code, you will be given the option to consolidate your bookings into a single, extended appointment.'
export const WALMART_HELP_CENTER_LINK = 'https://support.walmart.angi.com/';
export const GENERAL_HELP_CENTER_LINK = 'https://support.partners.angi.com/hc/en-us';

// Voucher Issuers
export const INCOMM = 'incomm';
export const HANDY_INCOMM = 'handy-incomm';
export const ANGI = 'angi'

// Voucher Status
export const ACTIVE = 'active';
export const INACTIVE = 'inactive'
export const CREATION_FAILED = 'creation_failed'

export const ANGI_VOUCHER_PARTNERS = ['samsung_wholesale']
