import React from 'react';
import PropTypes from 'prop-types';
import RatingsAndReviews from "./RatingsAndReviews";

class FormHeadline extends React.Component {

  headlineText() {
    const { serviceSlug, service, region, formTitle } = this.props;

    if (formTitle) {
      return formTitle;
    }

    if (serviceSlug) {
      return serviceSlug;
    }
    if (region.name && region.name.toLowerCase() === 'near me') {
      return `${service.name} ${region.name}`;
    } else if (region.name) {
      return `${region.name} ${service.name}`;
    } else {
      return service.name;
    }
  }

  constructor(props) {
    super(props);

    this.handleMediaChange = this.handleMediaChange.bind(this);

    this.state = { media: Foundation.MediaQuery.current };
  }

  handleMediaChange() {
    this.setState({ media: Foundation.MediaQuery.current });
  }

  componentDidMount() {
    $(window).on('changed.zf.mediaquery', this.handleMediaChange);
  }

  render() {
    const {
      ratingStarsHtml,
      reviewCountText,
      showReviewCount,
      metadata,
    } = this.props;

    const { media } = this.state;

    const reviewCountNum = metadata.review_count;

    if (media === 'small') {
      return (
        <div className='cell quote-form__header'>
          <h1>{this.headlineText()}</h1>
          { showReviewCount &&
          <RatingsAndReviews
            ratingStarsHtml={ratingStarsHtml}
            reviewCountText={reviewCountText}
          /> }
        </div>
      );
    } else {
      return (
        <h3>{this.headlineText()}</h3>
      );
    }
  }
}

FormHeadline.propTypes = {
  service: PropTypes.object,
  region: PropTypes.object,
  metadata: PropTypes.object,
  ratingStarsHtml: PropTypes.string,
  reviewCountText: PropTypes.string,
  showReviewCount: PropTypes.bool,
  serviceSlug: PropTypes.string,
  formTitle: PropTypes.string,
};

export default FormHeadline;
