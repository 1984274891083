import React from 'react';
import PropTypes from 'prop-types';
import Quote from '../models/Quote';
import SubmitButton from './quotes-form/SubmitButton';
import FormFields from './quotes-form/FormFields';
import FormHeadline from './quotes-form/FormHeadline';

const DATE_FORMAT_FROM_FORM = 'MM/DD/YYYY'
const TIME_FORMAT_FROM_FORM = 'HH:mm'

class QuotesFormContainer extends React.Component {
  applyValidation() {
    this.state.quote.invalidFields(this.props.service.form);
    this.setState({});
    this.scrollToError();
  }

  scrollToError() {
    setTimeout(() => {
      const errorMessage = $('form.quote-form .error');
      const invalidFields = $('.invalid');
      let target = null;

      if (errorMessage.length > 0) {
        target = errorMessage;
      } else if (invalidFields.length > 0) {
        target = invalidFields;
      }

      if (target) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 500);
      }
    }, 10);
  }

  constructor(props) {
    super(props);
    const quote = new Quote(props.service, {
      emailIsRequired: !this.props.userIsLoggedIn,
      userZipcode: this.props.userZipcode,
      voucherId: this.props.voucher_id,
      defaultDateStart: this.props.quotes_form.default_date_start,
      couponId: this.props.quotes_form.subscription_offer_coupon,
      serviceSlug: this.props.serviceSlug,
      email: this.props.email,
    });

    this.state = { quote: quote, processing: false, error: null, media: null }
  }

  formatPhone = (phone) => {
    return (phone ? phone.replace(/\D/g,'') : phone)
  }

  onSubmitForm = (event) => {
    this.applyValidation();

    if (this.state.processing || !this.state.quote.isValid()) {
      event.preventDefault();

      return;
    }

    event.preventDefault();
    const quote = this.state.quote;
    quote.attr.phone = this.formatPhone(quote.attr.phone)

    this.setState({ processing: true, error: null });

    let csrf_token = $("meta[name='csrf-token']").attr("content");

    if (typeof csrf_token === 'undefined') {
      csrf_token = this.props.authenticity_token
    }
    $.post({
      url: this.props.quotes_path,
      data: {
        quote: quote.attr,
        authenticity_token: csrf_token
      },
      success: (data) => {
        if (data.redirect_to) {
          window.location.href = data.redirect_to;
        } else {
          this.setState({ processing: false })
        }
      },
      error: (data) => {
        const error = data.responseJSON && data.responseJSON['error'];
        const invalidControls = data.responseJSON && data.responseJSON['invalid_fields'];
        const date = data.responseJSON && data.responseJSON['autofill_fields'].date;
        const startDateAutofill = this.formatDateTime(date, DATE_FORMAT_FROM_FORM);
        const startTimeAutofill = this.formatDateTime(date, TIME_FORMAT_FROM_FORM);

        if (error) {
          this.setState((prevState) => {
            const quote = prevState.quote;
            if (startDateAutofill) quote.attr.date = startDateAutofill;
            if (startTimeAutofill) quote.attr.start_time = startTimeAutofill;
            quote.invalidControls = quote.invalidControls.concat(invalidControls);

            return { quote: quote, error: error };
          });
        }

        this.setState({ processing: false })
        this.scrollToError();
      }
    })
  };

  updateQuote = (attrName, eventOrValue, type = 'attribute') => {
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;

    //Prevents error from disappearing after field autofill
    if (type === 'attribute' && this.state.quote.attr[attrName] === eventOrValue) return;

    this.setState((prevState) => {
      const quote = prevState.quote;

      if (type === 'attribute') {
        quote.attr[attrName] = value
      } else {
        quote.attr.details[attrName] = value;
        if (type === 'detail') {
          quote.recountBaseHours(attrName, this.props.service.form)
        } else if (type === 'question') {
          quote.changeNumberProviders(attrName, value, this.props.service.form.questions)
        }
      }

      return { quote: quote, error: null }
    })
  };

  formatDateTime = (dateTime, format) => {
    return dateTime ? moment(new Date(dateTime)).format(format) : null;
  }

  termsContent = () => {
    const machineName = this.props.service.machine_name
    let airbnbText = ''

    if (machineName === 'airbnb_enhanced_sanitization_cleaning') {
      airbnbText = `When you book a service through this page, some of your data may be shared back with Airbnb to help Airbnb improve its product
      and partnership offerings. If you don't want your data to be shared with Airbnb, please book a standard cleaning.`
    }

    return (
      <p className='grid-container quote-form-description'>
        By clicking “Get a Price,” I agree to the <a href='/terms' target='_blank'>Handy Terms</a> and <a href='/privacy' target='_blank'>Privacy Policy</a>,
        and I agree that Handy may use automated technology and pre-recorded messages to deliver marketing calls and texts regarding pros for my project and future projects to the number I provided.
        I understand that consent is not a condition of service.
      </p>
    )
  }

  render() {
    const serviceMachineName = this.props.service.machine_name;

    return (
      <div>
        <form className='quote-form' onSubmit={this.onSubmitForm}>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <FormHeadline
                service={this.props.service}
                region={this.props.region}
                metadata={this.props.metadata}
                ratingStarsHtml={this.props.ratingStarsHtml}
                reviewCountText={this.props.reviewCountText}
                showReviewCount={this.props.showReviewCount}
                serviceSlug={this.props.serviceSlug}
                formTitle={this.props.mobileFormTitle}
              />
              <FormFields
                quote={this.state.quote}
                error={this.state.error}
                userIsloggedIn={this.props.userIsLoggedIn}
                regionCountry={this.props.region && this.props.region.country}
                serviceForm={this.props.service.form}
                updateQuote={this.updateQuote}
                promotion={this.props.promotion}
                email={this.props.email}
                showPhoneCollection={this.props.showPhoneCollection}
              />
              <div className='cell small-12 quote-form__button'>
                <SubmitButton
                  processing={this.state.processing}
                  template={this.props.template}
                  serviceMachineName={serviceMachineName}
                  zipcode={this.state.quote.attr.zipcode}
                />
              </div>
            </div>
          </div>
        </form>
        {this.termsContent()}
      </div>
    );
 }
}

QuotesFormContainer.propTypes = {
  userIsLoggedIn: PropTypes.bool,
  userZipcode: PropTypes.string,
  authenticity_token: PropTypes.string,
  mixpanel_metadata: PropTypes.object,
  region: PropTypes.object,
  metadata: PropTypes.object,
  ratingStarsHtml: PropTypes.string,
  reviewCountText: PropTypes.string,
  showReviewCount: PropTypes.bool,
  quotes_path: PropTypes.string,
  service: PropTypes.shape({
    service: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      form: PropTypes.shape({
        default_hours: PropTypes.number
      }),
      machine_name: PropTypes.string,
    }),
  }),
  serviceSlug: PropTypes.string,
  mobileFormTitle: PropTypes.string,
  email: PropTypes.string,
};

export default QuotesFormContainer;
