import React from 'react';
import {
  MULTIPLE_VOUCHER,
  INCOMM,
  HANDY_INCOMM,
  ANGI,
  ANGI_VOUCHER_PARTNERS,
  ACTIVE,
  INACTIVE,
  CREATION_FAILED,
  WALMART_HELP_CENTER_LINK,
  GENERAL_HELP_CENTER_LINK,
} from './RedemptionFormConstants';
import emailValidator from '../../util/email-validator';
import voucherCodeValidator from '../../util/voucher-code-validator.js';
import { includes } from 'lodash';
import { post } from '../../util/fetch-api';
import appendMixpanelDistinctId from "../../util/mixpanel-util";

class RedemptionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email_address: '',
      code: '',
      errors: {},
      serverErrorCode: '',
      submitting: false,
    };

  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, errors: { [e.target.name]: false } });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ serverErrorCode: '', submitting: true });

    const errors = {
      email_address: !emailValidator(this.state.email_address),
      code: !voucherCodeValidator(this.state.code)
    };

    if (includes(errors, true)) {
      this.setState({ errors });
    } else {
      let voucherIssuer

      if (ANGI_VOUCHER_PARTNERS.includes(this.props.partnerName)) {
        voucherIssuer = ANGI;
      } else {
        voucherIssuer = this.state.email_address.includes(HANDY_INCOMM) ? HANDY_INCOMM : INCOMM;
      }

      const voucherRequestBody = {
        code: this.state.code,
        voucher_issuer: voucherIssuer,
      }

      const voucherResp = await post(`${this.props.landingPagesUrl}/api/v1/partner_redemption/external_vouchers`, voucherRequestBody)
      const voucherJson = await voucherResp.json();

      if (!voucherResp.ok) {
        this.setState({ serverErrorCode: voucherJson.code });
      } else if (voucherJson.external_voucher.status.name !== ACTIVE) {
        this.setState({ serverErrorCode: INACTIVE });
      } else {
        const orderRequestBody = {
          order: {
            partner_name: this.props.partnerName,
            purchase_type: this.props.purchaseType,
            partner_order_id: this.props.partnerOrderId,
            brand: this.props.brand,
            email: this.state.email_address,
            code: voucherJson.external_voucher.code,
            product_identifier: voucherJson.external_voucher.product_identifier,
            face_value: voucherJson.external_voucher.face_value,
            voucher_issuer: voucherJson.external_voucher.voucher_issuer,
          }
        }

        if(this.props.redirectAllUsersToLandingPages || this.props.redirectUserEmailsToLandingPages.includes(this.state.email_address)){
          // pass orderRequestBody to the new redeem info page somehow.
          // redirect to that new more info page.
        } else {
          const orderResp = await post(`${this.props.landingPagesUrl}/api/v1/partner_redemption/orders`, orderRequestBody)
          const orderJson = await orderResp.json();

          if (!orderResp.ok) {
            this.setState({ serverErrorCode: orderJson.code });
          } else {
            mixpanel && mixpanel.track('voucher_success', { event_context: 'voucher_redemption'});

            let redirect_url = appendMixpanelDistinctId(orderJson.redirect_to);

            window.location.href = redirect_url;
            return;
          }
        }
      }
    }
    this.setState({ submitting: false });
  }

  sanitizaPartnerName(partnerName){
    let cleanedPartnerName = partnerName.replace(/[^a-zA-Z ]/g, "").replace("wholesale", "");

    return cleanedPartnerName[0].toUpperCase() + cleanedPartnerName.slice(1);
  }

  getErrorMessage() {
    const { serverErrorCode } = this.state
    if([CREATION_FAILED, INACTIVE].includes(serverErrorCode)){
      return `There is an error with this activation code. Please reach out to a ${this.sanitizaPartnerName(this.props.partnerName)} representative for help.`
    }
    return "Oops! An error occurred while processing your request."
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="redemption-form">
          <h2 className="redemption-form-header">Redeem a Voucher</h2>
          <label className={this.state.errors.code ? 'error' : ''}>
            Activation code
            <input placeholder="000000000" name="code" value={this.state.code} onChange={this.onChange} />
          </label>
          {this.props.partnerName !== "samsung_wholesale" && <p className="multiple-vouchers">
            I purchased multiple vouchers&nbsp;
            <span data-tooltip="true" tabIndex="1" title={MULTIPLE_VOUCHER}>
              <i className="fal fa-info-circle" />
            </span>
          </p>}
          <label className={this.state.errors.email_address ? 'error' : ''}>
            Email
            <input placeholder="Email Address" name="email_address" value={this.state.email_address} onChange={this.onChange} />
          </label>
          <button disabled={this.state.submitting} className="redeem-button">Redeem Now {this.state.submitting ? <i className='fa fa-spinner fa-spin'/> : null}</button>
          {this.state.serverErrorCode && <p className="server-error-message">{this.getErrorMessage()}</p>}
          <p className="need-help">Need help? <a href={this.props.partnerName === "walmart" ? WALMART_HELP_CENTER_LINK : GENERAL_HELP_CENTER_LINK}>Visit our help center.</a></p>
        </div>
      </form>
    );
  }
}

export default RedemptionForm
