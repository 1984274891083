const appendMixpanelDistinctId = (redirectUrl) => {
  redirectUrl = new URL(redirectUrl);
  let queryParams = redirectUrl.searchParams;

  if (mixpanel && typeof mixpanel.get_distinct_id === 'function') {
    queryParams.set('umid', mixpanel.get_distinct_id());
  }
  redirectUrl.search = queryParams.toString();

  return redirectUrl;
};

export default appendMixpanelDistinctId;
