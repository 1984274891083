import React from 'react';
import PropTypes from 'prop-types';


class SubmitButton extends React.Component {

  getMixpanelEventContext = () => {
    const { template } = this.props;
    switch (template) {
      case 'neighborhood':
        return 'booking_funnel_neighborhood';
      case 'region':
        return 'booking_funnel_region';
      default:
        return 'booking_funnel';
    }
  };

  render() {
    const mixpanelEventContext = this.getMixpanelEventContext();
    const { serviceMachineName, zipcode } = this.props;

    return (
      <button
        className='button radius mixpanel'
        type='submit'
        data-mixpanel-event='quote_request_submitted'
        data-mixpanel-trigger='click'
        data-mixpanel-event-context={mixpanelEventContext}
        data-mixpanel-service-name={serviceMachineName}
        data-mixpanel-zipcode={zipcode}
      >
        {this.props.processing ? <i className='fa fa-spinner fa-spin'/> : 'Get a Price'}
      </button>
    );
  }
}

SubmitButton.propTypes = {
  processing: PropTypes.bool
};

export default SubmitButton;
