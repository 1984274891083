import React from 'react';
import PropTypes from 'prop-types';
import CalendarDatePicker from './CalendarDatePicker'
import MobileWebDatePicker from './MobileWebDatePicker'
import { DATE_POST_FORMAT } from './FormConstants'

class StartDate extends React.Component {
  constructor() {
    super();
    this.picker = null;
    this.dateDisplayFormat = 'ddd MMM DD';
    this.minAllowedDate = this.findMinDate();
    this.datesForDropdown = this.generateListOfDates();
    this.handleMobileDateChange = this.handleMobileDateChange.bind(this);
    this.setCalendarPicker = this.setCalendarPicker.bind(this);
  }

  componentDidMount() {
    if (this.picker) this.changePickerDate(this.props.date);
  }

  componentDidUpdate() {
    let calendarPickerDate = this.picker && this.getPostFormatFromDate(this.picker.getDate());
    let currentStateDate = this.props.date;
    if (calendarPickerDate !== currentStateDate) this.changePickerDate(new Date(this.props.date))
  }

  setCalendarPicker = picker => {
    this.picker = picker;
  }

  changePickerDate = date => {
    this.picker.setDate(date);
  }

  handleMobileDateChange = event => {
    const { value } = event.target;
    this.changePickerDate(value);
  }

  getPostFormatFromDate = date => {
    return moment(new Date(date)).format(DATE_POST_FORMAT);
  }

  generateListOfDates() {
    let dates = [];
    const dateRange = Array.from(Array(45).keys());
    const firstDate = new Date(this.minAllowedDate);
    firstDate.setHours(0, 0, 0, 0);

    dateRange.map( daysInFuture => {
      let dupedDay = new Date(firstDate.getTime());
      // add i to dupedDay object to not mutate `firstDate`
      const date = new Date(dupedDay.setDate(dupedDay.getDate() + daysInFuture))
      dates.push(this.getPostFormatFromDate(date));
    });

    return dates;
  }

  findMinDate = () => {
    const nowDate = new Date;
    const nowTime = moment(nowDate).format('HH:mm');
    const dateShift = (nowTime < '22:30') ? 2 : 3;

    return nowDate.setDate(nowDate.getDate() + dateShift);
  }

  render() {
    return (
      <div>
        <CalendarDatePicker
          date={this.props.date}
          updateData={this.props.updateData}
          setCalendarPicker={this.setCalendarPicker}
          dateDisplayFormat={this.dateDisplayFormat}
          minAllowedDate={moment(this.minAllowedDate).format(DATE_POST_FORMAT)}
          invalidClass={this.props.invalidClass}
          brand={this.props.brand}
        />
        <MobileWebDatePicker
          date={this.props.date}
          handleMobileDateChange={this.handleMobileDateChange}
          datesForDropdown={this.datesForDropdown}
          dateDisplayFormat={this.dateDisplayFormat}
          invalidClass={this.props.invalidClass}
          brand={this.props.brand}
        />
      </div>
    )
  }
}

StartDate.propTypes = {
  date: PropTypes.string,
  updateData: PropTypes.func
};

export default StartDate;
