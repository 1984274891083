const ServiceTheme = {
  container: {
    position: 'relative',
    width: '100%',
  },
  input: {
    height: '56px',
    width: '100%',
    border: '1px solid #D8D8D8',
    borderRadius: '5px 0 0 5px',
    boxShadow: 'none',
    margin: '0',
    padding: '1rem'
  },
  inputFocused: {
    borderColor: '#00cded'
  },
  inputOpen: {

  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    border: '1px solid #D8D8D8',
    borderTop: 'none',
    backgroundColor: 'white',
    zIndex: 20 //z index on this pretty much has to be higher than everything adjacent to it
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    height: '40px',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    overflow: 'hidden'
  },
  suggestionHighlighted: {
    backgroundColor: 'rgba(0,205,237,0.1)'
  }
};

export default ServiceTheme;
