import React from 'react';
import PropTypes from 'prop-types';


class FormError extends React.Component {
  render() {
    const error = this.props.error;

    if (error) {
      return <div className="small-12 columns error">
        <i className="fab fa fa-exclamation-triangle"/> {error}
      </div>
    } else {
      return '';
    }
  }
}

FormError.propTypes = {
  error: PropTypes.string
};

export default FormError;
