import React from 'react';
import PropTypes from 'prop-types';


class Questions extends React.Component {
  render() {
    let questions = [];

    this.props.questions.map((question, question_index) => {
      let options = [];
      const value = this.props.quote.attr.details[question.id];

      question.options.map((option, option_index) => {
        options.push(<option key={option_index} value={option[1]}>{option[0]}</option>)
      });

      questions.push(
        <div key={question_index}>
          <h5>{question.text}</h5>
          <div className='cell small-12'>
            <select
              value={value}
              onChange={(event) => {this.props.updateQuote(question.id, event, 'question')}}
            >
              {options}
            </select>
            {question.warn_on_option.includes(value) &&
              <p>{question.custom_warning}</p>
            }
          </div>
        </div>
      )
    });

    return (
      <div className="quote-form__questions">
        {questions}
      </div>
    )
  }
}

Questions.propTypes = {
  quote: PropTypes.object,
  updateQuote: PropTypes.func,
  questions: PropTypes.array
};

export default Questions
