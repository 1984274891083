const SEARCH_FOCUSED = "search_focused";
const SEARCH_UNFOCUSED = "search_unfocused";
const SEARCH_CLICKED = "search_result_clicked";

class ServicesSearchMixpanelHelper {
  static get FALLBACK_SEARCH_RANK() {
    return Number.MAX_SAFE_INTEGER; //This is the same number used in the search bar for general services
  }

  static trackSearchBarClicked = (eventContext, metadata, currentValue, index, matches) => {
    const filteredMatches = ServicesSearchMixpanelHelper.filterMatches(matches);

    let properties = {
      "event_context" : eventContext,
      "search_term" : currentValue,
      "categorizable_type" : matches[index].categorizable_type,
      "categorizable_machine_name" : matches[index].categorizable_machine_name,
      "number_of_results" : filteredMatches.length,
      "search_rank" : ServicesSearchMixpanelHelper.calculateDisplayableRank(matches[index].search_rank)
    };
    properties = {...properties, ...metadata};

    incrementSessionCount();
    mixpanel.track(SEARCH_CLICKED, properties);
  };

  static trackSearchBarUnfocused = (eventContext, metadata, currentValue, matches) => {
    const filteredMatches = ServicesSearchMixpanelHelper.filterMatches(matches);

    let properties = {
      "event_context" : eventContext,
      "search_term" : currentValue,
      "number_of_results" : filteredMatches.length,
      "search_results" : filteredMatches
    };
    properties = {...properties, ...metadata};

    incrementSessionCount();
    mixpanel.track(SEARCH_UNFOCUSED, properties);
  };

  static filterMatches = (matches) => {
    let filteredMatches = matches.filter(match => !match.illegitimate_match);
    filteredMatches = JSON.parse(JSON.stringify(filteredMatches)); //deep clone the matched array so we don't mutate the original objects

    for(let i = 0; i < filteredMatches.length; i++) {
      filteredMatches[i].search_rank = i + 1;
    }

    return filteredMatches;
  }

  static calculateDisplayableRank = (rank) => {
    return rank === ServicesSearchMixpanelHelper.FALLBACK_SEARCH_RANK ? "fallback" : rank;
  }

  static trackSearchBarFocused = (eventContext, metadata) => {
    let properties = { "event_context" : eventContext };
    properties = {...properties, ...metadata};

    incrementSessionCount();
    mixpanel.track(SEARCH_FOCUSED, properties);
  };
}

export default ServicesSearchMixpanelHelper;
