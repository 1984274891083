import React from 'react';

class TextInput extends React.Component {
  constructor() {
    super();

    this.state = {shrinked: false, value: ''};
  }

  shrinkSpan = () => {
    this.setState({shrinked: true});
  }

  checkInput = () => {
    if (this.state.value.trim() === '') {
      this.setState({shrinked: false});
    }
  }

  setValue = (e) => {
    this.setState({value: e.target.value});
    this.props.handleInputChange(e);
  }

  placeholderText = () => {
    const {value} = this.state;
    const {error} = this.props;

    if (error && value.length > 0) {
      return this.props.errorText;
    }

    return this.props.placeholderText;
  }

  render() {
    const klasses = [
      'text-input',
      'small-12',
      this.state.shrinked ? 'shrinked' : 'unshrinked',
      this.props.error ? 'error' : 'not-error',
    ];

    return (
      <div className={klasses.join(' ')}>
        <input onBlur={this.checkInput} onFocus={this.shrinkSpan} onChange={this.setValue} value={this.state.value} type="text" id={this.props.id}/>
        <span>{this.placeholderText()}</span>
      </div>
    )
  }
}

export default TextInput;
