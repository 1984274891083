const SCHEMA_URI = "iglu:com.angi.entities/retail_partner_attributes/jsonschema/1-0-0"

const retailPartnerAttributes = (partnerName) => ({
   schema: SCHEMA_URI,
   data: {
      machine_name: partnerName
   }
})

export default retailPartnerAttributes;
