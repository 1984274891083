import React from "react";
import PropTypes from 'prop-types';
import StartDate from "./ccp-form/StartDate";
import StartTime from "./ccp-form/StartTime";
import Notice from "./ccp-form/Notice";
import Validator from "./ccp-form/Validator";
import moment from "moment-timezone";

import _ from 'lodash';
import {
  CCP_FORM_FIELDS,
  DATE_POST_FORMAT,
  DATE_START_FORMAT,
  DEFAULT_NOTICE_MESSAGE,
  MIN_HOURS_BEFORE_BOOKING_START,
} from './ccp-form/FormConstants';
import appendMixpanelDistinctId from "../../util/mixpanel-util";

let ERROR_MESSAGES = {
  dateNotAvailable: "Please select a different date or time",
}

let validations = {
  email: {
    pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
    message: "Please enter a valid email address",
  },
  zipcode: {
    pattern: /^(\d{5}|\w{3}( \w{3})?)$/,
    message: "Please enter a valid zip code",
  },
};

const dynamicFieldValidations = {
  numeric: {
    pattern: "[0-9]",
    message: "can only contain numbers."
  },
  alphanumeric: {
    pattern: "[a-zA-Z0-9]",
    message: "can only contain numbers or letters."
  },
  alphabetic: {
    pattern: "[a-zA-Z]",
    message: "can only contain letters."
  },
  default: {
    pattern: "[\\s\\S]",
    message: "Please enter a valid"
  },
};

const brandedSubmitText = {
  angi: 'Continue',
  handy: 'Next',
};

class CCPFormContainer extends React.Component {
  constructor(props) {
    super(props);

    let data = {
      partner_name: props.partner_name,
      partner_order_id: props.partner_order_id,
      purchase_type: props.purchase_type,
      skus: [props.sku],
      service_machine_name: props.service_machine_name,
      service_duration_minutes: props.service_duration_minutes,
      dynamic_fields: {},
      plug_and_play_partner_name: props.plug_and_play_partner_name,
      brand: props.brand,
      email: '',
      zipcode: '',
      date: moment().add(4, 'days').format(DATE_POST_FORMAT),
      time: '',
    };

    if (props.dynamic_fields_config) {
      validations = {...this.generateDynamicFieldValidations(props.dynamic_fields_config, props.sku), ...validations}
      data = this.addDynamicFieldsToData(props.dynamic_fields_config, data)
     }

     this.validator = new Validator({
       validations: validations,
       onSuccess: this.proceedForm,
       onErrors: this.setErrors,
       onError: this.setError
     });

    this.state = {
      title: props.title,
      data: data,
      isDevelopmentEnv: props.development_env,
      dynamic_fields_config: props.dynamic_fields_config ? this.parseDynamicFields(props.dynamic_fields_config) : null,
      errors: {},
      errorMessage: null,
      processing: false,
      timezoneIdentifier: null,
      startsAt: null,
      zipcodeInfo: null,
      pricingInfo: null,
    };
  }

  componentDidMount() {
    const { data } = this.state;

    if (this.props.brand === 'angi') {
      const mixpanelProperties = {
        event_context: 'normandy_sku_page',
        sku: data.skus,
        partner_machine_name: data.partner_name,
        service_machine_name: data.service_machine_name,
      };
      mixpanel && mixpanel.track('angi_sku_page', {...mixpanelProperties});
    }
  }

  addDynamicFieldsToData = (dynamic_fields_config, data) => {
    const fields = this.parseDynamicFields(dynamic_fields_config)
    if (!fields) return data
    const fieldKeys = {}
    fields.forEach((field) => {
      fieldKeys[field.field_name] = ""
    })
    return {...data, ...fieldKeys}
  }

  parseDynamicFields = (dynamic_fields_config) => {
    try {
      return JSON.parse(dynamic_fields_config)
    } catch (err) {
      console.log(err)
      return null
    }
  }

  generateDynamicFieldValidations = (dynamic_fields_config, sku) => {
    const fields = this.parseDynamicFields(dynamic_fields_config)
    if (!fields) return {}

    return fields.reduce((regexPatterns, field) => {
      if (!("skus" in field) || field.skus.includes(sku)) {
        let regex = (field.validation in dynamicFieldValidations) ?
          dynamicFieldValidations[field.validation].pattern :
          dynamicFieldValidations.default.pattern;

        regex = field.include_whitespace ? '[\\s' + regex.substring(1) : regex
        const minLength = field.min_length ? `{${field.min_length}` : '{0'
        const maxLength = field.max_length ? `,${field.max_length}}` : ',}'
        const finalRegex = new RegExp('^' + regex + minLength + maxLength + '$');
        regexPatterns[field.field_name] = {
          pattern: finalRegex,
          message: (field.validation in dynamicFieldValidations) ?
            `${field.display_name} ${dynamicFieldValidations[field.validation].message}` :
            `${dynamicFieldValidations.default.message} ${field.display_name}.`
        }
      }
      return regexPatterns
    }, {})
  }

  updateData = (field, eventOrValue, dynamicField = false) => {
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;

    //Prevents error from disappearing after field autofill
    if (this.state.data[field] === value) return;

    this.clearError(field);

    if (dynamicField) {
      this.setState((prevState) => {
        prevState.data.dynamic_fields[field] = value

        return { ...prevState };
      });
    } else {
      this.setState((prevState) => {
        let stateChanges = { [field]: value }
        if (field === CCP_FORM_FIELDS.date) stateChanges[CCP_FORM_FIELDS.time] = '';

        return { data: { ...this.state.data, ...stateChanges } };
      });
    }

    if (this.props.brand === "handy") { // yuk. Get rid of this after all pages rebranded
      this.validator.validateField(field, value);
    }
  }

  setError = (field, message) => {
    this.setState((state) => {
      return { errors: { ...this.state.errors, [field]: message } };
    });
  }

  clearError = (field) => {
    const { errors } = this.state;
    delete errors[field];
    if (field === CCP_FORM_FIELDS.date) delete errors[CCP_FORM_FIELDS.time];
    this.setState({ errors: errors });
  }

  setErrors = errors => this.setState({ errors: errors });
  clearErrors = () => this.setState({ errors: {} });

  setErrorMessage = message => this.setState({ errorMessage: message });
  clearErrorMessage = () => this.setState({ errorMessage: null });

  startProcessing = () => this.setState({ processing: true });
  stopProcessing = () => this.setState({ processing: false });

  proceedForm = () => {
    this.startProcessing();
    this.submitForm()
        .finally(this.stopProcessing);
  }

  onSubmitForm = (event) => {
    event.preventDefault();
    this.clearErrors();
    this.validator.validate({...this.state.data, ...this.state.data.dynamic_fields});
  }

  submitForm = () => {
    if(this.props.redirect_all_users_to_landingpages || this.props.redirect_user_emails_to_landingpages.includes(this.state.data.email)){
      return this.prePaymentValidations().then((isValid) => {
        if (isValid) {
          return $.post({
            url: "/api/v1/partner_landing/orders/create_purchase_details",
            data: {
              order: {...this.state.data,
                ...{zip_code_info: this.state.zipcodeInfo.zip},
                ...{pricing_info: this.state.pricingInfo}},
              authenticity_token: this.csrfToken,
            },
            success: (data) => {
              window.location.href = data.redirect_to;
            },
            error: (data) => {
              let noticeMessage = DEFAULT_NOTICE_MESSAGE;
              if (this.state.isDevelopmentEnv && data.responseJSON && data.responseJSON.message) {
                noticeMessage = data.responseJSON.message;
              }

              if (data.status === 400) {
                const formFields = Object.keys(CCP_FORM_FIELDS);
                const errors = data.responseJSON.more_info;
                const invalidFields = Object.keys(errors).filter((field) => formFields.includes(field));
                _.each(invalidFields, (field) => this.validator.setError(field, errors[field].join(". ")));
                invalidFields.length === 0 ? this.setErrorMessage(noticeMessage) : this.clearErrorMessage();
              } else {
                this.clearErrors();
                this.setErrorMessage(noticeMessage);
              }
            }
          })
        } else {
          return Promise.reject;
        }
      });
    } else {
      return $.post({
        url: this.props.partner_landing_orders_path,
        data: {
          order: this.state.data,
          authenticity_token: this.csrfToken,
        },
        success: (data) => {
          let redirect_url = appendMixpanelDistinctId(data.redirect_to);

          window.location.href = redirect_url.toString();
        },
        error: (data) => {
          let noticeMessage = DEFAULT_NOTICE_MESSAGE;
          if (this.state.isDevelopmentEnv && data.responseJSON && data.responseJSON.message) {
            noticeMessage = data.responseJSON.message;
          }

          if (data.status === 400) {
            const formFields = Object.keys(CCP_FORM_FIELDS);
            const errors = data.responseJSON.more_info;
            const invalidFields = Object.keys(errors).filter((field) => formFields.includes(field));
            _.each(invalidFields, (field) => this.validator.setError(field, errors[field].join(". ")));
            invalidFields.length === 0 ? this.setErrorMessage(noticeMessage) : this.clearErrorMessage();
          } else {
            this.clearErrors();
            this.setErrorMessage(noticeMessage);
          }
        }
      })
    }
  }

  prePaymentValidations = () => {
    let isValid = true;
    return new Promise((resolve, reject) => {
      this.validateZipcodeCoverage()
          .then(() => {
            let startsAt = this.calculateStartTime();
            if (moment(startsAt, DATE_START_FORMAT).isBefore(moment().add(MIN_HOURS_BEFORE_BOOKING_START, 'hours'))) {
              this.validator.setError(CCP_FORM_FIELDS.time, ERROR_MESSAGES.dateNotAvailable);
              isValid = false;
            }
            if (isValid && this.state.data.time !== "") {
              return this.validateTimeCoverage(startsAt);
            } else {
              return Promise.resolve();
            }
          })
          .then(() => {
            if(isValid) {
              return this.doCostCalculation()
            } else {
              reject(isValid)
            }
          })
          .then(() => {
            resolve(isValid)
          })
          .catch(() => {
            reject(false);
          });
    });
  };

  validateZipcodeCoverage = () => {
    return $.get({
      url: `${this.props.handybook_host}/api/services/v1/zipcode_coverage`,
      data: {
        zipcode: this.state.data.zipcode,
        service_machine_name: this.props.service_machine_name,
      },
      success: (data) => {
        this.setState({timezoneIdentifier: data["zip"].timezone.timezone_identifier});
        this.setState({zipcodeInfo: data});
      },
      error: () => {
        this.validator.setError(CCP_FORM_FIELDS.zipcode, validations.zipcode.message);
        this.setErrors({'zipcode': validations.zipcode.message})
        this.clearErrorMessage();
      }
    })
  }

  validateTimeCoverage = (startsAt) => {
    return $.post({
      url: `${this.props.handybook_host}/api/services/v1/time_coverage`,
      data: $.param({
        quote_request: this.timeValidationParams(startsAt)
      }),
      error: () => {
        this.validator.setError(CCP_FORM_FIELDS.time, ERROR_MESSAGES.dateNotAvailable);
        this.setErrors({'time': ERROR_MESSAGES.dateNotAvailable})
        this.clearErrorMessage();
      }
    })
  }

  doCostCalculation = () => {
    return $.get({
      url: `${this.props.handybook_host}/api/services/v1/cost_calculation`,
      data: {
        partner_machine_name: this.state.data.partner_name,
        skus: JSON.stringify([{
          sku: this.props.sku,
          quantity: 1
        }])
      },
      success: (data) => {
        this.setState({pricingInfo: data});
      },
      error: (error) => {
        this.setErrorMessage(DEFAULT_NOTICE_MESSAGE);
      }
    })
  }

  timeValidationParams = (startsAt) => {
    return {
      user_originating_platform: "handy",
      service_name: this.props.service_machine_name,
      zipcode: this.state.data.zipcode,
      frequency: "one_time",
      starts_at: `${startsAt}`,
      commitment: "no_commitment",
      duration: this.props.service_duration_minutes,
      requires_responses: false,
      partner_machine_name: this.state.data.partner_name
    }
  }

  calculateStartTime = () => {
    const dateTimeString = `${this.state.data.date} ${this.state.data.time}`;
    const dateFormat = "MM/DD/YYYY HH:mm";
    const parsedTime = moment.tz(dateTimeString, dateFormat, this.state.timezoneIdentifier);
    return parsedTime.format(DATE_START_FORMAT);
  }

  csrfToken = () => {
    const tokenFromMeta = $("meta[name='csrf-token']").attr("content");

    return (typeof tokenFromMeta === 'undefined') ? this.props.authenticity_token : tokenFromMeta
  }

  invalidInputClass = (fieldName) => {
    return this.state.errors.hasOwnProperty(fieldName) ? 'invalid' : '';
  }

  render() {
    return (
      <div className={`form-box ${this.props.brand === 'angi' ? 'angi-branded-form' : 'handy-branded-form'}`}>
        {this.props.brand === 'angi' ? (
          <div>
            <div className="title">
              Get started here.
            </div>
            <div className="subtitle">
              Schedule and book your service.
            </div>
          </div>
        ) : (
          <div className="title">
            {this.state.title}
          </div>
        )}
        <form className="form" onSubmit={this.onSubmitForm}>
          <div className="form-group">
            <input
              className={this.invalidInputClass(CCP_FORM_FIELDS.email)}
              type="text"
              name={CCP_FORM_FIELDS.email}
              placeholder="Email"
              onChange={(event) => {this.updateData(CCP_FORM_FIELDS.email, event)}}
            />
            { this.state.errors[CCP_FORM_FIELDS.email] ?
              <div className="input-error">{this.state.errors[CCP_FORM_FIELDS.email]}</div> : null }
          </div>

          <div className="form-group">
            <input
              className={this.invalidInputClass(CCP_FORM_FIELDS.zipcode)}
              type="text"
              name={CCP_FORM_FIELDS.zipcode}
              placeholder="Zip code"
              onChange={(event) => {this.updateData(CCP_FORM_FIELDS.zipcode, event)}}
            />
            { this.state.errors[CCP_FORM_FIELDS.zipcode] ?
              <div className="input-error">{this.state.errors[CCP_FORM_FIELDS.zipcode]}</div> : null }
          </div>
          { this.state.dynamic_fields_config ?
            this.state.dynamic_fields_config.map((field, idx) => {
              if (!("skus" in field) || field.skus.includes(this.props.sku)) {
                return (
                  <div key={idx} className="form-group">
                    <input
                      className={this.invalidInputClass(field.field_name)}
                      type="text"
                      name={field.display_name}
                      placeholder={field.placeholder_text}
                      onChange={(event) => {this.updateData(field.field_name, event, true)}}
                    />
                    { this.state.errors[field.field_name] ?
                      <div className="input-error">{this.state.errors[field.field_name]}</div> : null }
                  </div>
                )
              }
            }) : null }
          {this.props.brand === 'angi' ? null : <label>Let us know when your pro should come</label>}
          <div className="grid-x">
            {this.props.brand === 'angi' ? (
              <div className="cell small-12 start-date-container angi-date">
                <StartDate
                  date={this.state.data.date}
                  updateData={this.updateData}
                  invalidClass={this.invalidInputClass(CCP_FORM_FIELDS.time)}
                  brand={this.props.brand}
                />
              </div>
            ) : (
              <div className="cell large-6 small-12 start-date-container">
                <StartDate
                  date={this.state.data.date}
                  updateData={this.updateData}
                  invalidClass={this.invalidInputClass(CCP_FORM_FIELDS.time)}
                  brand={this.props.brand}
                />
              </div>
            )}
            {this.props.brand === 'angi' ? (
              <div className="cell small-12 start-time-container angi-time">
                <StartTime
                  updateData={this.updateData}
                  invalidClass={this.invalidInputClass(CCP_FORM_FIELDS.time)}
                  selectedDate={this.state.data.date}
                  brand={this.props.brand}
                />
              </div>
            ) : (
              <div className="cell large-6 small-12 start-time-container">
                <StartTime
                  updateData={this.updateData}
                  invalidClass={this.invalidInputClass(CCP_FORM_FIELDS.time)}
                  selectedDate={this.state.data.date}
                  brand={this.props.brand}
                />
              </div>
            )}
            { this.state.errors[CCP_FORM_FIELDS.time] ?
              <div className="input-error">{this.state.errors[CCP_FORM_FIELDS.time]}</div> : null }
          </div>
          <button disabled={this.state.processing} className="button" type="submit">
            {this.state.processing ? <i className='fa fa-spinner fa-spin'/> : brandedSubmitText[this.props.brand]}
          </button>
          { this.state.errorMessage ? <Notice message={this.state.errorMessage}/> : null }
        </form>
      </div>
    );
  }
}

CCPFormContainer.propTypes = {
  title: PropTypes.string,
  partner_landing_orders_path: PropTypes.string,
  partner_name: PropTypes.string,
  partner_order_id: PropTypes.string,
  purchase_type: PropTypes.string,
  sku: PropTypes.string,
  development_env: PropTypes.bool,
  authenticity_token: PropTypes.string,
  service_machine_name: PropTypes.string,
  service_duration_minutes: PropTypes.number,
  plug_and_play_partner_name: PropTypes.string,
  redirect_all_users_to_landingpages: PropTypes.bool,
  redirect_user_emails_to_landingpages: PropTypes.array,
  handybook_host: PropTypes.string
};

export default CCPFormContainer;
