import React from 'react';

const ServiceDropDown = ({ serviceMachineName, error, onSelect, services }) => {
  const klasses = [
    error ? 'error' : 'not-error',
    serviceMachineName === '' ? 'unselected' : 'value-selected',
  ];

  return (
    <div className="service-dropdown small-12">
      <select className={klasses.join(" ")} onChange={onSelect} defaultValue="none">
        <option value="none" disabled>Please select a primary service</option>
        {services.map(({ machine_name: machineName, display_name: displayName }) => (
          <option key={machineName} value={machineName}>{displayName}</option>
        ))}
      </select>
    </div>
  );
}

export default ServiceDropDown;
