import React from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import createTrie from 'autosuggest-trie';

import theme from './services-search-bar/ServiceTheme';
import mpHelper from './services-search-bar/ServicesSearchMixpanelHelper';

const MAX_SEARCH_RESULTS = 6;
const SEARCH_ENDPOINT = "/services/service_search";
const NOT_FOUND_REDIRECT_PATH = "/services";

const deepCompare = (first, second) => {
  return JSON.stringify(first) == JSON.stringify(second);
};

class ServicesSearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      searchValue: '',
      suggestions: [],
      prevSearchValue: '',
      prevSuggestions: [],
      isLoaded: false,
      eventContext: props.event_context,
      mixpanelMetadata: props.mixpanel_metadata
    };

    //these bindings are necessary to reference 'this' in the callback
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    this.trackSearchButtonClick = this.trackSearchButtonClick.bind(this);
    this.calculateMatchedSuggestions = this.calculateMatchedSuggestions.bind(this);
    this.getAllResults = this.getAllResults.bind(this);
  }

  componentDidMount = () => {
    axios.get(SEARCH_ENDPOINT)
      .then(response => {
        let results = response.data.search_results;
        this.sortByRank(results);

        const matchingService = this.createMatchingService(results);

        this.setState({
          isLoaded: true,
          allResults: results,
          matchingService: matchingService
        });
      });
  };

  createMatchingService = (services) => {
    services.push(this.getGeneralService());

    return createTrie(services, 'short_display_name');
  };

  getNotFoundSuggestion = (value) => {
    return {
      "short_display_name" : `\"${value}\" not found...`,
      "original_value" : value,
      "when_page_url" : NOT_FOUND_REDIRECT_PATH,
      "search_rank" : 0,
      "illegitimate_match" : true,
      "not_found" : true
    };
  };

  getSuggestionValue = (suggestion) => {
    if(suggestion.not_found) return suggestion.original_value;

    return suggestion.short_display_name;
  };

  getGeneralService = () => {
    return {
      "short_display_name" : "Book a General Service >",
      "search_rank" : mpHelper.FALLBACK_SEARCH_RANK,
      "when_page_url" : "/services/other-handyman-service",
      "illegitimate_match" : true
    };
  };

  serviceIsMatched = (matches, service) => {
    for (let match of matches) {
      if(deepCompare(match, service)) return true;
    }

    return false;
  };

  sortByRank = (matches) => {
    matches.sort((a, b) => a.search_rank - b.search_rank);
  };

  getAllResults = () => {
    if(!this.state.isLoaded) return [];

    return this.state.allResults;
  };

  calculateMatchedSuggestions = (value) => {
    let matches = [];
    if(!this.state.isLoaded) return matches;

    if(value) {
      matches = this.state.matchingService.getMatches(value);
      this.sortByRank(matches);
    }
    else {
      matches = this.getAllResults();
    }

    matches = matches.slice(0, MAX_SEARCH_RESULTS);

    if(matches.length == 0) matches.push(this.getNotFoundSuggestion(value));

    const generalService = this.getGeneralService();
    if(!this.serviceIsMatched(matches, generalService)) matches.push(generalService);

    return matches;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    let matches = this.calculateMatchedSuggestions(value);

    this.setState({
      prevSuggestions: this.state.suggestions,
      prevSearchValue: this.state.searchValue,
      suggestions: matches,
      searchValue: value
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      prevSuggestions: this.state.suggestions,
      prevSearchValue: this.state.searchValue,
      suggestions: [],
      searchValue: ''
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    const searchValue = this.state.searchValue;
    const suggestions = this.state.suggestions;

    mpHelper.trackSearchBarClicked(this.state.eventContext, this.state.mixpanelMetadata, searchValue, suggestionIndex, suggestions);

    this.setState({
      //set the state's value to it's original value to cause the field to remain whatever was typed in when we click the not found suggestion
      value: suggestion.not_found ? this.state.value : suggestionValue
    });

    this.redirectToSuggestionPath(suggestion);
  };

  onNotFoundSuggestionClicked = (event) => {
    event.stopPropagation();
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onBlur = (event, { highlightedSuggestion }) => {
    const currentValue = this.state.searchValue;
    const suggestions = this.calculateMatchedSuggestions(currentValue);

    mpHelper.trackSearchBarUnfocused(this.state.eventContext, this.state.mixpanelMetadata, currentValue, suggestions);
  };

  onSearchBarClick = (event) => {
    mpHelper.trackSearchBarFocused(this.state.eventContext, this.state.mixpanelMetadata);
  };

  onSearchButtonClick = (event) => {
    const currentValue = this.state.value;
    const suggestions = this.calculateMatchedSuggestions(currentValue);

    if(suggestions.length == 0) return;

    const firstSuggestion = suggestions[0];

    this.trackSearchButtonClick(firstSuggestion);
    this.redirectToSuggestionPath(firstSuggestion);
  };

  trackSearchButtonClick = (suggestion) => {
    const suggestions = this.state.prevSuggestions;
    const searchValue = this.state.prevSearchValue;
    const suggestionIndex = suggestions.findIndex(s => deepCompare(s, suggestion));

    //need to add this sanity check in case the search button is clicked without even typing anything into the bar
    if(suggestions.length === 0 || suggestionIndex === -1) return;

    // hitting search button causes blur, then clear since we're moving out of the search bar,
    // so we track the original search term and its suggestions by using their prev values
    mpHelper.trackSearchBarClicked(this.state.eventContext, this.state.mixpanelMetadata, searchValue, suggestionIndex, suggestions);
  }

  redirectToSuggestionPath = (suggestion) => {
    if(!suggestion.when_page_url) return;

    window.location.href = suggestion.when_page_url;
  };

  shouldRenderSuggestions = (value) => {
    return true;
  };

  renderSuggestion = (suggestion) => {
    const linkClass = suggestion.illegitimate_match ? "suggestion-link-grayed" : "suggestion-link-matched";

    if(suggestion.when_page_url) {
      return (
        <a href={suggestion.when_page_url} className={linkClass}>
          {suggestion.short_display_name}
        </a>
      );
    }

    return (
      <div className={linkClass} onClick={this.onNotFoundSuggestionClicked}>
        {suggestion.short_display_name}
      </div>
    );
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'Search for a home service...',
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onClick: this.onSearchBarClick
    };

    return (
      <div className='services-search-bar-container'>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={this.renderSuggestion}
          highlightFirstSuggestion={true}
          inputProps={inputProps}
          theme={theme}
        />
        <button className='search-button-container' onClick={this.onSearchButtonClick}>
        </button>
      </div>
    );
  }
}

export default ServicesSearchBar;
