import React from 'react';
import PropTypes from 'prop-types';

class Notice extends React.Component {
  render() {
    return (
      <div className = 'form-notice'>
        {this.props.message}
      </div>
    )
  }
}

Notice.propTypes = {
  message: PropTypes.string,
};

export default Notice;
