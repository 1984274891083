import React from 'react';
import PropTypes from 'prop-types';


const START_TIME_DETAILS = {hours: 7, minutes: 0};
const END_TIME_DETAILS = {hours: 21, minutes: 0};
const STEP_MINUTES = 30;

class StartTime extends React.Component {
  render() {
    let options = [];
    const startTime = moment(START_TIME_DETAILS);
    const endTime = moment(END_TIME_DETAILS);

    for (let moment = startTime; moment <= endTime; moment.add(STEP_MINUTES, 'minutes')) {
      options.push(
        <option key={moment.unix()} value={moment.format('HH:mm')}>
          {moment.format('h:mm A')}
        </option>
      );
    }
    return <select
      className={this.props.invalidClass}
      value={this.props.startTime}
      name='quote_start_time'
      onChange={(event) => {this.props.updateQuote('start_time', event)}}
    >
      {options}
    </select>
  }
}

StartTime.propTypes = {
  startTime: PropTypes.string,
  updateQuote: PropTypes.func
};

export default StartTime;
