import React from 'react';
import PropTypes from 'prop-types';
import CalendarDatePicker from './CalendarDatePicker'
import MobileWebDatePicker from './MobileWebDatePicker'

class StartDate extends React.Component {
  constructor() {
    super();
    this.picker = null;
    this.dateDisplayFormat = 'ddd MMM DD';
    this.datePostFormat = 'MM/DD/YYYY';
    this.datesForDropdown = this.generateListOfDates();
    this.handleMobileDateChange = this.handleMobileDateChange.bind(this);
    this.setCalendarPicker = this.setCalendarPicker.bind(this);
  }

  componentDidMount() {
    if (this.picker) this.changePickerDate(this.props.date);
  }

  componentDidUpdate() {
    let calendarPickerDate = this.picker && this.getPostFormatFromDate(this.picker.getDate());
    let currentStateDate = this.props.date;
    if (calendarPickerDate !== currentStateDate) this.changePickerDate(new Date(this.props.date))
  }

  setCalendarPicker = picker => {
    this.picker = picker;
  }

  changePickerDate = date => {
    this.picker.setDate(date);
  }

  handleMobileDateChange = event => {
    const { value } = event.target;
    this.changePickerDate(value);
  }

  getPostFormatFromDate = date => {
    return moment(new Date(date)).format(this.datePostFormat);
  }

  generateListOfDates() {
    let dates = [];
    const dateRange = Array.from(Array(45).keys());
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    dateRange.map( daysInFuture => {
      let dupedDay = new Date(today.getTime());
      // add i to dupedDay object to not mutate `today`
      const date = new Date(dupedDay.setDate(dupedDay.getDate() + daysInFuture))
      dates.push(this.getPostFormatFromDate(date));
    });

    return dates;
  }

  render() {
    return (
      <div>
        <CalendarDatePicker
          date={this.props.date}
          updateQuote={this.props.updateQuote}
          setCalendarPicker={this.setCalendarPicker}
          dateDisplayFormat={this.dateDisplayFormat}
          datePostFormat={this.datePostFormat}
          invalidClass={this.props.invalidClass}
        />
        <MobileWebDatePicker
          date={this.props.date}
          handleMobileDateChange={this.handleMobileDateChange}
          datesForDropdown={this.datesForDropdown}
          dateDisplayFormat={this.dateDisplayFormat}
          invalidClass={this.props.invalidClass}
        />
      </div>
    )
  }
}

StartDate.propTypes = {
  date: PropTypes.string,
  updateQuote: PropTypes.func
};

export default StartDate;
