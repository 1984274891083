import React from 'react';
import PropTypes from 'prop-types';
import BaseHours from './BaseHours';
import FormError from './FormError';
import Questions from './Questions';
import QuoteDetails from './QuoteDetails';
import StartDate from './StartDate';
import StartTime from './StartTime';
import PhoneField from './PhoneField'

class FormFields extends React.Component {
  zipcodePlaceholder() {
    if (this.props.regionCountry === 'GB') {
      return 'Postal Code';
    } else {
      return 'ZIP Code';
    }
  }

  shouldDisplayPhone() {
    return !this.props.userIsLoggedIn && this.props.showPhoneCollection && (this.props.regionCountry === 'US')
  }

  render() {
    const { quote } = this.props;
    const { email, phone } = quote.attr

    return (
      <div className='cell'>
        <FormError
          error={this.props.error}
        />
        <div className='cell small-12'>
          <input
            className="hidden"
            type="text"
          />
          <input
            className={ this.props.quote.invalidControls.includes('zipcode') ? 'invalid' : '' }
            type='text'
            placeholder={this.zipcodePlaceholder()}
            onChange={(event) => {this.props.updateQuote('zipcode', event)}}
            value={this.props.quote.attr.zipcode}
            name="zip"
            autocomplete="postal-code"
          />
        </div>
        <h4>{this.props.serviceForm.service_intro}</h4>
        <QuoteDetails
          quote={this.props.quote}
          attributes={this.props.serviceForm.attributes}
          updateQuote={this.props.updateQuote}
          tabName={this.props.serviceForm.tab_name}
        />
        <Questions
          quote={this.props.quote}
          questions={this.props.serviceForm.questions}
          updateQuote={this.props.updateQuote}
        />
        {this.props.serviceForm.need_comment_box &&
          <div className='cell small-12'>
            <textarea
              className={ this.props.quote.invalidControls.includes('notes') ? 'invalid' : '' }
              cols='40'
              rows='5'
              placeholder={this.props.serviceForm.comment_box_placeholder}
              value={this.props.quote.attr.notes}
              onChange={(event) => {this.props.updateQuote('notes', event)}}
            />
          </div>
        }
        {quote.attr.showDuration &&
          <BaseHours
            baseHours={this.props.quote.attr.base_hours}
            updateQuote={this.props.updateQuote}
            serviceForm={this.props.serviceForm}
            recommendedHours={this.props.quote.recommendedHours(this.props.serviceForm)}
            promotion={this.props.promotion}
          />
        }
        <h5>When would you like a Pro to come?</h5>
        <div className='cell small-12'>
          <StartDate
            date={this.props.quote.attr.date}
            updateQuote={this.props.updateQuote}
            invalidClass={this.props.quote.invalidControls.includes('date') ? 'invalid' : '' }
          />
        </div>
        <div className='cell small-12'>
          <StartTime
            startTime={this.props.quote.attr.start_time}
            updateQuote={this.props.updateQuote}
            invalidClass={this.props.quote.invalidControls.includes('start_time') ? 'invalid' : '' }
          />
        </div>
        { this.props.userIsLoggedIn ||
          <div className='cell small-12'>
            <input
              className={ this.props.quote.invalidControls.includes('email') ? 'invalid' : '' }
              placeholder='Email Address'
              type='email'
              pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
              onChange={(event) => {this.props.updateQuote('email', event)}}
              value={email}
              name="email"
              autocomplete="email"
            />
          </div>
        }
        { this.shouldDisplayPhone() &&
          <div className='cell small-12'>
            <PhoneField
              classNames={this.props.quote.invalidControls.includes('phone') ? 'invalid' : ''}
              updateQuote={this.props.updateQuote}
              phone={phone}
              name="phone"
            />
          </div>
        }
      </div>
    )
  }
}

FormFields.defaultProps = {
  regionCountry: 'US'
};

FormFields.propTypes = {
  quote: PropTypes.object,
  error: PropTypes.string,
  userIsLoggedIn: PropTypes.bool,
  updateQuote: PropTypes.func,
  serviceForm: PropTypes.object
};

export default FormFields;
