const zipRegex = /^\d{5}$/;

const zipValidator = (zip) => {
  if (typeof zip !== 'string') {
    return false;
  }
  if (zip === '') {
    return false;
  }
  if (!zip.match(zipRegex)) {
    return false;
  }

  return true;
};

export default zipValidator;
