import React from 'react';
import PropTypes from 'prop-types';
import {
  BUSINESS_HOURS_RANGE,
  CCP_FORM_FIELDS,
  DATE_POST_FORMAT,
  TIME_POST_FORMAT,
  MIN_HOURS_BEFORE_BOOKING_START
} from './FormConstants';

class StartTime extends React.Component {
  constructor(props){
    super(props);
    this.stepMinutes = 30;
  }

  momentAsDateObject = (dateTime) => {
    return {
      years: dateTime.year(),
      months: dateTime.month(),
      days: dateTime.date(),
    };
  };

  render() {
    const currentTime = moment();
    const selectedDate = moment(this.props.selectedDate, DATE_POST_FORMAT);
    const startTime = moment({ ...BUSINESS_HOURS_RANGE.start, ...this.momentAsDateObject(selectedDate) })
    const endTime = moment({ ...BUSINESS_HOURS_RANGE.end, ...this.momentAsDateObject(selectedDate) })

    let options = [
      <option key='any-time' value=''>Any time</option>,
    ];

    for (let time = startTime; time < endTime; time.add(this.stepMinutes, 'minutes')) {
      const hoursFromNow = moment.duration(time.diff(currentTime)).asHours();
      if (hoursFromNow <= MIN_HOURS_BEFORE_BOOKING_START) continue;

      options.push(
        <option key={time.unix()} value={time.format(TIME_POST_FORMAT)}>
          {time.format('h:mm A')}
        </option>
      );
    }

    return this.props.brand === 'angi' ? (
      <div style={{ position: 'relative' }}>
        <span>Service Time</span>
        <select
          className={this.props.invalidClass}
          name={CCP_FORM_FIELDS.time}
          onChange={(event) => {this.props.updateData(CCP_FORM_FIELDS.time, event)}}
        >
          {options}
        </select>
      </div>
    ) : (
      <select
        className={this.props.invalidClass}
        name={CCP_FORM_FIELDS.time}
        onChange={(event) => {this.props.updateData(CCP_FORM_FIELDS.time, event)}}
      >
        {options}
      </select>
    )
  }
}

StartTime.propTypes = {
  updateData: PropTypes.func,
  selectedDate: PropTypes.string,
};

export default StartTime;
