import React from 'react'
import Snowplow from "@angi/snowplow-react";
import PageViewWrapper from "./PageViewWrapper";

const APP_ID = 'service-landingpages-frontend'

const TrackerWrapper = ({partnerName, brand, collectorUrl, postPath}) => {
   const config ={
      appId: APP_ID,
      collectorUrl,
      builtInGlobalContextProperties: {
         brandName: brand
      },
      trackerConfig: {}
   }

   if (postPath !== null) {
      config.trackerConfig.postPath = postPath
   }

   return(
      <Snowplow configuration={config}>
         <PageViewWrapper partnerName={partnerName}/>
      </Snowplow>
   )
}

export default TrackerWrapper
