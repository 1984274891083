const DEFAULT_TIME_HOUR_MIN = 10;
const DEFAULT_TIME_HOUR_MAX = 17;

export const MIN_BASE_HOURS = 2;
export const MAX_BASE_HOURS = 10;
const AIRBNB_ENHANCED_SANITIZATION_CLEANING_HOURS =
  {
    0: { 0: 3.5, 1: 3.5, 2: 4, 3: 4.5, 4: 5, 5: 5.5, 6: 6, 7: 6.5, 8: 7, 9: 7.5, 10: 8 },
    1: { 0: 3.5, 1: 3.5, 2: 4, 3: 4.5, 4: 5, 5: 5.5, 6: 6, 7: 6.5, 8: 7, 9: 7.5, 10: 8 },
    2: { 0: 3.75, 1: 3.75, 2: 4.25, 3: 4.75, 4: 5.25, 5: 5.75, 6: 6.25, 7: 6.75, 8: 7.25, 9: 7.75, 10: 8.25 },
    3: { 0: 4, 1: 4, 2: 4.5, 3: 5, 4: 5.5, 5: 6, 6: 6.5, 7: 7, 8: 7.5, 9: 8, 10: 8.5 },
    4: { 0: 4.25, 1: 4.25, 2: 4.75, 3: 5.25, 4: 5.75, 5: 6.25, 6: 6.75, 7: 7.25, 8: 7.75, 9: 8.25, 10: 8.75 },
    5: { 0: 4.5, 1: 4.5, 2: 5, 3: 5.5, 4: 6, 5: 6.5, 6: 7, 7: 7.5, 8: 8, 9: 8.5, 10: 9 },
    6: { 0: 4.75, 1: 4.75, 2: 5.25, 3: 5.75, 4: 6.25, 5: 6.75, 6: 7.25, 7: 7.75, 8: 8.25, 9: 8.75, 10: 9.25 },
    7: { 0: 5, 1: 5, 2: 5.5, 3: 6, 4: 6.5, 5: 7, 6: 7.5, 7: 8, 8: 8.5, 9: 9, 10: 9.5 },
    8: { 0: 5.25, 1: 5.25, 2: 5.75, 3: 6.25, 4: 6.75, 5: 7.25, 6: 7.75, 7: 8.25, 8: 8.75, 9: 9.25, 10: 9.75 },
    9: { 0: 5.5, 1: 5.5, 2: 6, 3: 6.5, 4: 7, 5: 7.5, 6: 8, 7: 8.5, 8: 9, 9: 9.5, 10: 10 },
    10: { 0: 5.75, 1: 5.75, 2: 6.25, 3: 6.75, 4: 7.25, 5: 7.75, 6: 8.25, 7: 8.75, 8: 9.25, 9: 9.75, 10: 10 }
  }


class Quote {
  constructor(serviceArgs={}, options={}) {
    this.invalidControls = [];
    this.attr = { details: {} };
    this.attr.zipcode = options.userZipcode || '';
    this.attr.notes = '';
    this.attr.service_id = serviceArgs.id;
    this.attr.service_machine_name = serviceArgs.machine_name ;
    this.attr.base_hours = serviceArgs.form.default_hours;
    this.attr.date = options.defaultDateStart;
    this.attr.start_time = this.defaultStartTime();
    this.attr.number_providers = 1;

    //temporarily hard coding to hide BaseHours component from airbnb pilot when page
    this.attr.showDuration = (serviceArgs.machine_name !== 'airbnb_enhanced_sanitization_cleaning')

    serviceArgs.form.attributes.map((attribute) => {
      const attrName = this.attrName(attribute);

      this.attr.details[attrName] = attribute.default_value;
    });

    serviceArgs.form.questions.map((question) => {
      this.attr.details[question.id] = question.selected;
    });

    if (options.emailIsRequired) {
      this.attr.email = options.email || '';
    }

    this.attr.coupon_id = options.couponId;
    this.attr.voucher_id = options.voucherId;
  }

  defaultStartTime = () => {
    const defaultTimeHour = Math.floor(Math.random() * (DEFAULT_TIME_HOUR_MAX - DEFAULT_TIME_HOUR_MIN + 1)) + DEFAULT_TIME_HOUR_MIN;

    return moment({ hours: defaultTimeHour, minutes: 0 }).format('HH:mm');
  };

  recountBaseHours = (attrName, form) => {
    const recommended = this.recommendedHours(form);
    const maxValue = form.max_base_hours || MAX_BASE_HOURS;

    if (recommended >= maxValue) {
      this.attr.base_hours = maxValue;
    } else {
      this.attr.base_hours = recommended;
    }
  };

  recommendedHours = (form) => {
    let baseHoursValues = form.base_hours_values;

    if (this.attr.service_machine_name === "airbnb_enhanced_sanitization_cleaning") {
      baseHoursValues = AIRBNB_ENHANCED_SANITIZATION_CLEANING_HOURS
    }

    if (!jQuery.isEmptyObject(baseHoursValues)) {
      return this.bedBathEstimate(baseHoursValues)
    } else {
      let value = 0;

      form.attributes.map((attribute) => {
        const attrName = this.attrName(attribute);
        const hoursValue = attribute.hours_value;

        if (hoursValue) {
          value += hoursValue * this.attr.details[attrName];
        }
      });

      value = Math.round((value / this.attr.number_providers) * 2) / 2;

      return form.default_hours > value ? form.default_hours : value;
    }
  };

  changeNumberProviders = (questionId, selected, questions) => {
    const question = questions.filter(obj=> obj.id === questionId)[0];
    const numberProvidersHash = question.number_providers;

    if (numberProvidersHash) {
      this.attr.number_providers = numberProvidersHash[selected];
    }
  };

  bedBathEstimate = (values) => {
    const details = this.attr.details;

    return values[details.bedrooms][details.bathrooms];
  };

  attrName = (attribute) => {
    return attribute.name.toLowerCase();
  };

  isValid = () => {
    return this.invalidControls.length === 0;
  };

  invalidFields = (form) => {
    let requiredFields = ['zipcode', 'date', 'start_time', 'email'];

    if (form.comment_box_required) {
      let index = requiredFields.indexOf('zipcode');

      requiredFields.splice(index + 1, 0, 'notes');
    }

    this.invalidControls = [];

    requiredFields.forEach((field) => {
      if (this.attr[field] === '') {
        this.invalidControls.push(field);
      }
    });
  };
}

export default Quote;
