import React from 'react'
import PropTypes from 'prop-types'

const RatingsAndReviews = (props) => {
  const {
    ratingStarsHtml,
    reviewCountText,
  } = props

  const ratingStarsInnerHtml = createInnerHtmlMarkup(ratingStarsHtml)
  const reviewCountInnerHtml = createInnerHtmlMarkup(reviewCountText)

  return (
    <div>
        <span dangerouslySetInnerHTML={ratingStarsInnerHtml}/>
        <a href='#customer-reviews' data-smooth-scroll='' className='review-counter' dangerouslySetInnerHTML={reviewCountInnerHtml}/>
    </div>
  )
}

const createInnerHtmlMarkup = (content) => {
  return {__html: content}
}


RatingsAndReviews.propTypes = {
  ratingStarsHtml: PropTypes.string,
  reviewCountText: PropTypes.string,
}

export default RatingsAndReviews
