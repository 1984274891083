import React from 'react';
import Pikaday from 'pikaday';
import PropTypes from 'prop-types';
import { DATE_POST_FORMAT } from './FormConstants';

class CalendarDatePicker extends React.Component {
  componentDidMount = () => {
    let { date, dateDisplayFormat, minAllowedDate } = this.props;
    let picker = new Pikaday({
      field: this.dateInput,
      format: dateDisplayFormat,
      setDefaultDate: true,
      defaultDate: new Date(date),
      minDate: new Date(minAllowedDate),
      onSelect: (date) => { this.props.updateData('date', moment(date).format(DATE_POST_FORMAT)) }
    });

    this.props.setCalendarPicker(picker);
  };

  render() {
    return this.props.brand === 'angi' ? (
      <div className = 'show-for-medium' style={{ position: 'relative' }}>
        <span>Service date</span>
        <input
          ref={(input) => {this.dateInput = input}}
          type='text'
          required='required'
          name="date"
          className={`start-date ${this.props.invalidClass} angi-branded`}
        />
      </div>
    ) : (
      <div className = 'show-for-medium'>
        <input
          ref={(input) => {this.dateInput = input}}
          type='text'
          required='required'
          name="date"
          className={`start-date ${this.props.invalidClass}`}
        />
      </div>
    )
  }
}

CalendarDatePicker.propTypes = {
  date: PropTypes.string,
  updateData: PropTypes.func,
  setCalendarPicker: PropTypes.func,
  dateDisplayFormat: PropTypes.string,
  minAllowedDate: PropTypes.string,
};

export default CalendarDatePicker;
