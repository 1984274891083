import React from 'react';

class PhoneField extends React.Component {
  handlePhoneChange = (event) => {
    const { value } = event.target
    event.target.value = this.formatPhone(value)
    this.props.updateQuote('phone', event)
  }

  formatPhone = (value) => {
    let phoneNumber = value.replace(/\D/g,'').substring(0, 10)

    // from https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript
    const size = phoneNumber.length;

    if (size === 0) {
      phoneNumber
    } else if (size < 4) {
      phoneNumber = '(' + phoneNumber;
    } else if (size < 7) {
      phoneNumber = '(' + phoneNumber.substring(0,3) + ') ' + phoneNumber.substring(3,6);
    } else {
      phoneNumber = '(' + phoneNumber.substring(0,3) + ') ' + phoneNumber.substring(3,6)+'-' + phoneNumber.substring(6,10);
    }

    return phoneNumber;
  }

  render() {
    const { classNames, phone } = this.props

    return (
      <input
        className={classNames}
        placeholder='Phone Number (Optional)'
        type='tel'
        pattern='^(1?)(-| ?)(\()?([0-9]{3})(\)|-| |\)-|\) )?([0-9]{3})(-| )?([0-9]{4}|[0-9]{4})$'
        onChange={(event) => {this.handlePhoneChange(event)}}
        value={phone}
        name='phone'
        autocomplete='phone'
      />
    )
  }
}

 export default PhoneField



