import React from "react";

const MobileWebDatePicker = props => {
  let dates = props.datesForDropdown;

  return props.brand === 'angi' ? (
    <div className='caret-small-only show-for-small-only' style={{ position: 'relative' }}>
      <span>Service date</span>
      <select
        name='date'
        defaultValue={props.date}
        onChange={props.handleMobileDateChange}
        className={`form-input show-for-small-only ${props.invalidClass}`}
      >
        {dates.map((date, i) => {
          let dateOption = moment(new Date(date)).format(props.dateDisplayFormat)
          return <option key={i} value={date}>
            {dateOption}
          </option>;
        })}
      </select>
    </div>
  ) : (
    <div className='caret-small-only show-for-small-only' >
      <select
        name='date'
        defaultValue={props.date}
        onChange={props.handleMobileDateChange}
        className={`form-input show-for-small-only ${props.invalidClass}`}
      >
        {dates.map((date, i) => {
          let dateOption = moment(new Date(date)).format(props.dateDisplayFormat)
          return <option key={i} value={date}>
            {dateOption}
          </option>;
        })}
      </select>
    </div>
  );
}

export default MobileWebDatePicker;
