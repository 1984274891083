import React from 'react';
import Pikaday from 'pikaday';
import PropTypes from 'prop-types';

class CalendarDatePicker extends React.Component {
  componentDidMount = () => {
    let { date, datePostFormat, dateDisplayFormat } = this.props;
    let picker = new Pikaday({
      field: this.dateInput,
      format: dateDisplayFormat,
      setDefaultDate: true,
      defaultDate: new Date(date),
      minDate: new Date,
      onSelect: (date) => {
        this.props.updateQuote('date', moment(date).format(datePostFormat))}
    });

    this.props.setCalendarPicker(picker);
  };

  render() {
    return (
      <div className = 'show-for-medium'>
        <input
          ref={(input) => {this.dateInput = input}}
          type='text'
          required='required'
          className={`start-date ${this.props.invalidClass}`}
        />
      </div>
    )
  }
}

CalendarDatePicker.propTypes = {
  date: PropTypes.string,
  updateQuote: PropTypes.func,
  setCalendarPicker: PropTypes.func,
  dateDisplayFormat: PropTypes.string,
  datePostFormat: PropTypes.string
};

export default CalendarDatePicker;
