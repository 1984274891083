import React from 'react';
import TextInput from './TextInput';
import ServiceDropDown from './ServiceDropdown';
import emailValidator from '../../util/email-validator';
import zipValidator from '../../util/zip-validator';
import { post } from '../../util/fetch-api';
import { includes } from 'lodash';

const DEFAULT_ERROR = 'An unexpected error has occurred.';
const errorMap = {};
const ACTION_TYPE = 'external_direct_company_onboard';

const DIRECT_ONBOARDING_PAGE_SHOWN = "direct_onboarding_page_shown";
const DIRECT_ONBOARDING_PAGE_ERROR = "direct_onboarding_page_error";
const DIRECT_ONBOARDING_PAGE_SUBMISSION = "direct_onboarding_page_submission";

class CompanyApplicationForm extends React.Component {
  constructor() {
    super();

    this.state = {
      email: '',
      zip: '',
      serviceMachineName: '',
      errors: { email: false, serviceMachineName: false },
      serverErrors: { code: '' },
      submitting: false,
    };
  }

  componentDidMount() {
    mixpanel && mixpanel.track(DIRECT_ONBOARDING_PAGE_SHOWN, this.mixpanelProperties());
  }

  setEmail = (e) => {
    this.setState({
      email: e.target.value,
      errors: {
        ...this.state.errors,
        email: false,
      }
    });
  }

  setZip = (e) => {
    this.setState({
      zip: e.target.value,
      errors: {
        ...this.state.errors,
        zip: false,
      }
    });
  }

  setService = (e) => {
    this.setState({
      serviceMachineName: e.target.value,
      errors: {
        ...this.state.errors,
        serviceMachineName: false,
      }
    });
  }

  submissionParams = () => {
    return {
      company_application: {
        candidate_company: {
          email: this.state.email,
          zip_code: this.state.zip,
        },
        service_machine_name: this.state.serviceMachineName,
        action_type: ACTION_TYPE,
      },
    };
  }

  submitApplication = () => {
    const errors = {
      email: !emailValidator(this.state.email),
      zip: this.props.zipEnabled && !zipValidator(this.state.zip),
      serviceMachineName: this.state.serviceMachineName === '',
    };
    let success = false;

    if (includes(errors, true)) {
      mixpanel && mixpanel.track(DIRECT_ONBOARDING_PAGE_ERROR, this.mixpanelProperties());

      this.setState({ errors });
    } else {
      mixpanel && mixpanel.track(DIRECT_ONBOARDING_PAGE_SUBMISSION, this.mixpanelProperties());
      this.setState({ submitting: true, serverErrors: { code: '' } });
      post(`${this.props.svccompaniesPublicUrl}/api/internal/v1/company_applications`, this.submissionParams())
      .then(resp => {
        success = resp.ok
        return resp.json()
      })
      .then(json => {
        if (success) {
          window.location.href = `${this.props.svccompaniesPublicUrl}/company_applications/${json.company_application.external_id}`;
        } else {
          this.setState({ submitting: false, serverErrors: { code: json.error_code || 'unhandled' } });
        }
      }).catch(() => {
        this.setState({ submitting: false, serverErrors: { code: 'unhandled' }});
      })
    }
  }

  mixpanelProperties = () => {
    const mixpanelMetadata = window.mixpanelMetadata;
    const properties = {
      event_context: "company_onboarding",
      application_type: "",
      primary_service: this.state.serviceMachineName,
      user_email: this.state.email,
      variant: this.props.variantConfiguration.variant
    };

    return Object.assign({}, mixpanelMetadata, properties);
  }

  render() {
    return (
      <div className="grid-x">
        <div className="small-12">
          <h2>{this.props.variantConfiguration.sign_up_title}</h2>
        </div>
        <div className="email-container-subheader small-12">
          <p>Please enter your information to get started.</p>
        </div>
        <TextInput error={this.state.errors.email} handleInputChange={this.setEmail} id="company-apply-email-input" placeholderText="Company Email Address" errorText="Please input a valid email." />
        {this.props.zipEnabled && <TextInput error={this.state.errors.zip} handleInputChange={this.setZip} id="company-apply-zip-input" placeholderText="Zip Code" errorText="Please input a valid zip code." />}
        <ServiceDropDown serviceMachineName={this.state.serviceMachineName} error={this.state.errors.serviceMachineName} onSelect={this.setService} services={this.props.services} />
        <div className="join-button small-12">
          <button onClick={this.submitApplication}>{this.props.variantConfiguration.cta} {this.state.submitting ? <i className='fa fa-spinner fa-spin'/> : null}</button>
          {this.state.serverErrors.code !== '' ? <span className="server-error">{errorMap[this.state.serverErrors.code] || DEFAULT_ERROR}</span> : null}
        </div>
        <div className="link-container small-12">
          <a href="https://www.handy.com/apply?utm_source=DCOpagenotacompany">Not a company?</a>
        </div>
      </div>
    )
  }
}

export default CompanyApplicationForm
