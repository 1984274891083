const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const emailValidator = (email) => {
  if (typeof email !== 'string') {
    return false;
  }
  if (email === '') {
    return false;
  }
  if (!email.match(emailRegex)) {
    return false;
  }
  return true;
};

export default emailValidator;
