const voucherCodeRegex = /^\w+$/;

const voucherCodeValidator = (voucherCode) => {
  if (typeof voucherCode !== 'string') {
    return false;
  }
  if (voucherCode === '') {
    return false;
  }
  if (!voucherCode.match(voucherCodeRegex)) {
    return false;
  }
  return true;
};

export default voucherCodeValidator;
