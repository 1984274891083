import React from 'react';
import PropTypes from 'prop-types';


class QuoteDetails extends React.Component {
  isMounTv = () => {
    return (this.props.tabName === 'TVs');
  };

  minValue = () => {
    return this.isMounTv() ? 1 : 0;
  };

  maxValue = () => {
    return this.isMounTv() ? 6 : 10;
  };

  reduceValue = (attribute) => {
    const newValue = this.props.quote.attr.details[attribute] - 1;

    if (newValue >= this.minValue()) {
      this.props.updateQuote(attribute, newValue, 'detail')
    }
  };

  increaseValue = (attribute) => {
    const newValue = this.props.quote.attr.details[attribute] + 1;

    if (newValue <= this.maxValue()) {
      this.props.updateQuote(attribute, newValue, 'detail')
    }
  };

  render() {
    let attributes = [];

    this.props.attributes.map((attribute, index) => {
      const attrName = attribute.name.toLowerCase();
      const value = this.props.quote.attr.details[attrName];
      let detailsName = attrName;

      if (this.isMounTv()) {
        detailsName = (value === 1) ? 'TV' : 'TVs'
      } else if (attribute.description) {
        detailsName = (
          <span data-tooltip='' title={attribute.description}>
            {attrName} <i className='info' />
          </span>
        )
      }

      attributes.push(
        <div key={index} className='cell grid-x small-12 quote-form__details'>
          <span className='cell small-2 decrement' onClick={() => {this.reduceValue(attrName)}}>
            &#8722;
          </span>
          <span className='cell auto description'>
            {value} {detailsName}
          </span>
          <span className='cell small-2 increment' onClick={() => {this.increaseValue(attrName)}}>
            &#43;
          </span>
        </div>
      )
    });

    return <div>{attributes}</div>
  }
}

QuoteDetails.propTypes = {
  quote: PropTypes.object,
  updateQuote: PropTypes.func,
  attributes: PropTypes.array,
  tabName: PropTypes.string
};

export default QuoteDetails
