import React from 'react';
import PropTypes from 'prop-types';
import { MIN_BASE_HOURS, MAX_BASE_HOURS } from '../../models/Quote';


const STEP_HOURS = 0.5;

class BaseHours extends React.Component {
  title() {
    if (this.props.serviceForm.attributes.length === 0) {
      return 'How many hours would you like to book?';
    } else {
      return 'We recommend ' + this.props.recommendedHours + ' hours';
    }
  }

  render() {
    const serviceForm = this.props.serviceForm;
    if (serviceForm.tab_name === 'TVs') {
      return '';
    }

    let options = [];
    const baseHours = this.props.baseHours;
    const start = serviceForm.min_base_hours || MIN_BASE_HOURS;
    const end = serviceForm.max_base_hours || MAX_BASE_HOURS;

    // Promotion param in the URL should only happen if the promo is valueoffer on the monolith
    // So then we disable the hours for this sam's club promotion
    const promotion = this.props.promotion;
    const disableHours = (promotion !== null);

    for (let i = start; i <= end; i += STEP_HOURS) {
      options.push(<option key={i} value={i}>{i} hours</option>)
    }

    return (
      <div>
        <h5>{this.title()}</h5>
        <div className='cell small-12'>
          <select
            value={baseHours}
            onChange={(event) => {this.props.updateQuote('base_hours', event)}}
            disabled={disableHours}
          >
            {options}
          </select>
        </div>
        {(this.props.recommendedHours > baseHours) &&
          <p>Because you are booking less time than recommended, we may not be able to finish the job.</p>
        }
      </div>
    )
  }
}

BaseHours.propTypes = {
  baseHours: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  updateQuote: PropTypes.func,
  serviceForm: PropTypes.object,
  recommendedHours: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default BaseHours;
