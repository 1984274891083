import React from "react";
import { useEnhancedPageView } from "@angi/snowplow-react";
import retailPartnerAttributes from "./contexts/retailPartnerAttributes";

const PAGE_NAME = 'Retail: Redeem A Voucher'

const PageViewWrapper = ({partnerName}) => {
   const context = retailPartnerAttributes(partnerName)
   const params = {
      pageName: PAGE_NAME,
      pageViewContexts: [context]
   }
   useEnhancedPageView(params)

   return null;
}

export default PageViewWrapper
