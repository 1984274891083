import _ from 'lodash'

class Validator {
  constructor({
    validations,
    onSuccess = () => {},
    onErrors = (errors) => {},
    onError = (field, message) => {}
  }) {
    this.validations = validations
    this.onSuccess = onSuccess
    this.onErrors = onErrors
    this.onError = onError
    this.errors = {}
  }

  setError = (field, message = "") => {
    this.errors[field] = message
    this.onError(field, message)
  }

  clearError = field => delete this.errors["field"]
  clearErrors = () => this.errors = {}
  hasErrors = () => !_.isEmpty(this.errors)

  validateField = (field, value) => {
    this.clearError(field)
    if (this.validations[field]["pattern"].test(value)) return
    this.setError(field, this.validations[field]["message"])
  }

  validate = (data) => {
    this.clearErrors()

    _.each(_.keys(this.validations), (field) => {
      this.validateField(field, data[field])
    })

    this.hasErrors() ? this.onErrors(this.errors) : this.onSuccess()
  }
}

export default Validator
