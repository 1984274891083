export const CCP_FORM_FIELDS = {
  email: 'email',
  zipcode: 'zipcode',
  date: 'date',
  time: 'time',
};
export const TIME_POST_FORMAT = 'HH:mm';
export const DATE_POST_FORMAT = 'MM/DD/YYYY';
export const DATE_START_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const BUSINESS_HOURS_RANGE = {
  start: { hours: 7, minutes: 0 },
  end: { hours: 23, minutes: 0 },
};
export const MIN_HOURS_BEFORE_BOOKING_START = 48;
export const DEFAULT_NOTICE_MESSAGE = "Your booking could not be processed. Please try again.";
